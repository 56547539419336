<template>
  <div>
    <vs-card>
      <div class="flex items-center justify-between h-16 px-4">
        <div class="flex items-center">
          <feather-icon
            icon="HashIcon"
            svg-classes="h-8 w-8"
            class="mr-4 icon_color"
          />
          <p class="brief_color">
            المعرف : {{ currentRole.id }}
          </p>
        </div>

        <div class="flex items-center">
          <feather-icon
            icon="UserCheckIcon"
            svg-classes="h-8 w-8"
            class="mr-4 icon_color"
          />
          <p class="brief_color">
            اسم الدور : {{ currentRole.name }}
          </p>
        </div>

        <div class="flex items-center">
          <feather-icon
            icon="MailIcon"
            svg-classes="h-8 w-8"
            class="mr-4 icon_color"
          />
          <p class="brief_color">
            الاسم بالعربية : {{ currentRole.name_ar }}
          </p>
        </div>
      </div>
    </vs-card>


    <div class="mt-10">
      <vx-drag-and-drop-multiple-lists
        :list1="projectPermissions"
        :list2="addedPermissions"
        list1title="كافةالصلاحيات"
        list2title="الصلاحيات المراد إضافتها لهذا الدور"
      >
        <permissions-filter @getPermissionsByProject="getPermissionsByProject($event)"/>
      </vx-drag-and-drop-multiple-lists>
    </div>

    <div class="flex items-center justify-center mt-10">
      <vs-button
        :disabled="isLoading"
        type="filled"
        ycolor="success"
        @click.prevent="onSubmit()"
      >
        حفظ التغييرات
      </vs-button>
    </div>
  </div>
</template>

<script>
import {SharedRepositoryFactory} from "@/app/shared/shared-repositories/sharedRepositoryFactory";
import permissionsFilter from "@/app/shared/shared-components/PermissionsFilter";

const roleRepo = SharedRepositoryFactory.get('roleRepository');
const permissionRepo = SharedRepositoryFactory.get('permissionRepository');
export default {
  name: "RoleMain",
  components: {
    permissionsFilter
  },
  data() {
    return {
      currentRole: {},
      role: {
        id: null,
        name: '',
        name_ar: '',
        desc: ''
      },
      isLoading: true,
      permissions: [],
      permission: {
        id: null,
        name: '',
        name_ar: '',
        desc: ''
      },
      addedPermissions: [],
      projectPermissions: [],
      project: {}
    };
  },
  methods: {
    onSubmit() {
      const ids = this.addedPermissions.map(item => item.id);
      permissionRepo.givePermissionToRole(ids, this.$route.params.id).then(() => {
        this.fetchAllRoles();
        this.isLoading = false;
      });
    },
    getPermissionsByProject(project) {
      this.project = project;
      this.projectPermissions = [];
      this.permissions.forEach(item => {
        if (item.project_name === project.projectName)
          this.projectPermissions.push(item);
      });
    },
    fetchAllRoles() {
      roleRepo.fetchAllRoles().then((data) => {
        data.forEach(item => {
          if (item.id == this.$route.params.id) {
            this.addedPermissions = item.permissions;
            this.currentRole = item;
          }
        });
        this.fetchAllPermissions();
        this.isLoading = false;
      });
    },
    fetchAllPermissions() {
      permissionRepo.fetchAllPermissions().then((data) => {
        this.permissions = data.filter(item => !this.addedPermissions.find(permission => (permission.name === item.name)));
        this.getPermissionsByProject(this.project);
      });
    }
  },
  created() {
    this.project = {projectName: 'default', showName: 'صلاحيات لوحة التحكم'};
    this.fetchAllRoles();
  }
};
</script>


<style scoped>
.icon_color {
  color: #4B364E !important;
}

.brief_color {
  color: #888888;
  font-size: 18px;
  font-weight: 600;
}
</style>
