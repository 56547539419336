<template>
  <!-- class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"> -->
  <div class="flex items-center">
    <p class="per_color">
      صلاحيات :
    </p>
    <vs-dropdown
      class="cursor-pointer ml-4"
      vs-trigger-click
    >
      <div
        class="p-3 per_drop_color cursor-pointer flex items-center justify-between font-medium"
      >
        <span class="mr-2">{{ selectedProject.showName }}</span>
        <feather-icon
          icon="ChevronDownIcon"
          svg-classes="h-5 w-5"
        />
      </div>
      <vs-dropdown-menu>
        <div
          v-for="(project,index) in projects"
          :key="index"
        >
          <vs-dropdown-item @click="getPermissions(project)">
            <span>{{ project.showName }}</span>
          </vs-dropdown-item>
        </div>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
export default {
  name: "PermissionsFilter",
  data() {
    return {
      projects: [
        {projectName: 'default', showName: 'لوحة التحكم'},
        {projectName: 'website', showName: 'الموقع'},
        {projectName: 'ecommerce', showName: 'المتجر الالكتروني'},
        {projectName: 'donation', showName: 'التبرعات'},
        {projectName: 'events', showName: 'الحفلات'},
        {projectName: 'raven', showName: 'رافن'},
        {projectName: 'barcode', showName: 'الباركود'},
        {projectName: 'rihabAlHabeb', showName: 'رحاب الحبيب'},
        {projectName: 'zadi', showName: 'زادي'}
      ],
      selectedProject: {}
    };
  },
  methods: {
    getPermissions(project) {
      this.selectedProject = project;
      this.$emit('getPermissionsByProject', project);
    }
  },
  created() {
    this.selectedProject = {projectName: 'default', showName: 'لوحة التحكم'};
  }
};
</script>


<style scoped>
.per_color {
  color: #888888;
  font-weight: 600;
  font-size: 18px;
}

.per_drop_color {
  background: #5E445A;
  color: white;
  border-radius: 5px;
}

</style>
